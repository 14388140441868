<template>
    <div>
        <v-card>
            <v-card-title class="title">
                <v-flex xs3 sm3>
                    <v-select :disabled="gradeLoading" :loading="gradeLoading" :items="grades" class="pa-0"
                              label="Grade" v-model="grade" outlined dense/>
                </v-flex>
                <v-flex xs3 sm3>
                    <v-select :disabled="!grade || grade==='all'" :items="sections" class="pa-0" label="Section"
                              v-model="section" outlined dense/>
                </v-flex>
                <v-flex xs2>
                    <v-flex xs4>
                        <search-button style="" :disabled="!section && grade!=='all'" permission="billing-report-collection"
                                       @action="get()">
                            Search
                        </search-button>
                    </v-flex>
                </v-flex>
                <v-spacer></v-spacer>
                <print-buttons :passAuthToReport="true" v-if="downloadUrl && form.items.data.length > 0"
                               :download-url="downloadUrl"
                               :pdf="true" :excel="true" labelExcel="Download Excel" labelPdf="Download Pdf"></print-buttons>

                <!--<v-spacer></v-spacer>-->
            </v-card-title>
        </v-card>
        <v-data-table :headers="headers" hide-default-footer
                      :items="form.items.data" :search="search"
                      :options.sync="pagination" :loading="form.loading"
        >
            <template  v-slot:item="{index, item}">
                <tr>
                    <td class="text-xs-left">{{ index +1}}</td>
                    <td class="text-xs-center">{{item.transaction_month}}</td>
                    <td class="text-xs-center">
                        {{(item.collection_amount).numberFormat()}}
                    </td>
                    <td class="text-xs-center">{{item.advance_amount.numberFormat()}}</td>
                    <td class="text-xs-center">{{item.instant_discount.numberFormat()}}</td>
                </tr>
            </template>


            <template v-slot:body.append v-if="form.items.data.length > 0">
                <tr>
                    <th :colspan="2" class="text-xs-center">Total</th>
                    <th class="text-xs-center">{{(totalCollectionAmount-totalAdvanceAmount).numberFormat()}}</th>
                    <th class="text-xs-center">{{totalAdvanceAmount.numberFormat()}}</th>
                    <th class="text-xs-center">{{totalInstantDiscountAmount.numberFormat()}}</th>
                </tr>
            </template>
<!--            <template slot="footer" v-if="form.items.data.length > 0">-->
<!--                <tr>-->
<!--                    <th :colspan="3" width="56%" class="text-xs-center">Total</th>-->
<!--                    <th class="text-xs-center">{{(totalCollectionAmount-totalAdvanceAmount).numberFormat()}}</th>-->
<!--                    <th class="text-xs-center">{{totalAdvanceAmount.numberFormat()}}</th>-->
<!--                    <th class="text-xs-center">{{totalInstantDiscountAmount.numberFormat()}}</th>-->
<!--                </tr>-->
<!--            </template>-->
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
                Your search for "{{ search }}" found no results.
            </v-alert>
        </v-data-table>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins';
    import {validateDate} from "../../../../../library/helpers";

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        mixins: [Mixins],
        data: () => ({
            gradeLoading: false,
            form: new Form({
                bill_month: '',
                grade_id: '',
                enroll: ''
            }, '/api/report/bill-collection'),
            search: null,
            downloadUrl: '',
            totalSum: 0,
            studentsCollection: [],
            sdate: nd.format('YYYY-MM-DD'),
            // sdate: '2076-02-08',
            headers: [
                {text: 'S. No', align: 'left', value: 'id', sortable: false},
                // {text: 'Date', align: 'left', value: 'bill_date', sortable: false},
                {text: 'Month', align: 'centre', value: 'bill_date', sortable: false},
                // {text: 'Bill Amount', align: 'centre', value: 'bill_amount', sortable: false},
                {text: 'Collected Amount', align: 'centre', value: 'collection_amount', sortable: false},
                {text: 'Advance Amount', align: 'centre', value: 'advance_amount', sortable: false},
                {text: 'Instant Discount', align: 'centre', value: 'instant_discount', sortable: false},
                // {text: 'Due Amount', align: 'centre', value: 'due_amount', sortable: false},
                // {text: 'Avdance Amount', align: 'centre', value: 'advance_amount', sortable: false},
                // {text: 'Extra Amount', align: 'centre', value: 'extra_amount', sortable: false},

            ],
            grades: [],
            grade: '',
            sections: [],
            section: '',
            collection: [],
            extra: [],
            advance: [],
            totalCollectionAmount: 0,
            totalInstantDiscountAmount: 0,
            totalAdvanceAmount: 0,

        }),

        computed: {
            ...mapState(['batch']),
        },

        mounted() {
            this.getGrades();
        },

        watch: {
            'batch': function (value) {
                // this.get();
            },

            'grade': function (value) {
                this.section = '';
                if (this.grade !== 'all')
                    this.getSection();

            },
        },

        methods: {
            get(params) {
                let extraParams = 'sectionId=' + this.section + '&gradeId=' + this.grade;
                let query = [null, undefined].includes(params) ? this.queryString(extraParams) : params;
                this.form.get(null, query).then(({data}) => {
                    this.collection = data.collection;
                    this.downloadUrl = data.download_url;
                    this.totalCollectionAmount = 0;
                    this.totalInstantDiscountAmount = 0;
                    this.totalAdvanceAmount = 0;


                    this.extra = data.extra;
                    this.advance = data.advance;

                    //collection sum amount
                    // data.collection.map(res => {
                    //     this.totalCollectionAmount += parseFloat(res.total_amount);
                    // });

                    // bill Sum amount
                    data.data.map(r => {
                        this.totalCollectionAmount += parseFloat(r.collection_amount);
                        this.totalInstantDiscountAmount += parseFloat(r.instant_discount);
                        this.totalAdvanceAmount += parseFloat(r.advance_amount);
                    });

                    this.pagination.totalItems = data.meta.total
                })
            },

            save() {
                this.form.batchId = this.batch.id;
                this.form.grade_id = this.grade;
                this.form.bill_month = this.month;
                this.form.store()
            },

            getGrades() {
                this.gradeLoading = true;
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true').then(({data}) => {
                    let $this = this;
                    this.grades = [{value: 'all', text: 'ALL'}];

                    data.data.map(item => {
                        $this.grades.push({value: item.id, text: item.name});
                    });
                }).finally(() => {
                    this.gradeLoading = false;
                })
            },

            getSection() {
                if (this.grade) {
                    this.$rest.get('/api/section?grade=' + this.grade).then(({data}) => {
                        this.sections = data.data.map(item => {
                            return {value: item.id, text: item.name}
                        });
                    })
                }
            },
            dateFormat(data) {
                if (data.length === 8) return data = data.substr(0, 4) + '-' + data[4] + data[5] + '-' + data.slice(-2);
                else return data;
            },
            monthName(month) {
                switch (month) {
                    case '01':
                        return "Baishakh"
                        break;
                    case '02':
                        return "Jestha"
                        break;
                    case '03':
                        return "Ashadh"
                        break;
                    case '04':
                        return "Shrawan"
                        break;
                    case '05':
                        return "Bhadra"
                        break;
                    case '06':
                        return "Ashoj"
                        break;
                    case '07':
                        return "Kartik"
                        break;
                    case '08':
                        return "Mangsir"
                        break;
                    case '09':
                        return "Poush"
                        break;
                    case '10':
                        return "Magh"
                        break;
                    case '11':
                        return "Falgun"
                        break;
                    case '12':
                        return "Chaitra"
                        break;
                }
            }

        }
    }
</script>
<style lang="scss">
    .completed {
        border-radius: 11px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: white;
        margin: 5px;
    }

    .bred {
        background: #ff5252;
    }

    .bgreen {
        background: #4caf50;
    }

    .byellow {
        background: #ff9800;
    }
</style>